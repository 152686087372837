import React, { useEffect, useState } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import AnchorLink from "react-anchor-link-smooth-scroll"
import Scrollspy from "react-scrollspy"
import Img from "gatsby-image"
import { Nav, Brand, StyledContainer, ActionsContainer } from "./style"

export default function Navigation(props) {
  const short = useStaticQuery(graphql`
    query {
      file(sourceInstanceName: { eq: "images" }, name: { eq: "logo-text" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  const registered =
    typeof window !== "undefined" && window.localStorage.getItem("wait")

  const search = props.location.search
  const [hasScrolled, setHasScrolled] = useState(false)

  const handleScroll = () => {
    const scrollTop = window.pageYOffset

    if (scrollTop > 32) {
      setHasScrolled(true)
    } else {
      setHasScrolled(false)
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
  }, [])

  return (
    <Nav {...props} scrolled={hasScrolled}>
      <StyledContainer>
        <Brand>
          <Scrollspy offset={-64} item={["top"]} currentClassName="active">
            <AnchorLink href="#top">
              <Link to={`/${search}`}>
                <div style={{ width: "100px" }}>
                  <Img
                    fluid={short?.file?.childImageSharp?.fluid}
                    imgStyle={{ objectFit: "contain" }}
                  />
                </div>
              </Link>
            </AnchorLink>
          </Scrollspy>
        </Brand>
        <ActionsContainer>
          {registered && (
            <Link to={`/waitlist/${registered}`}>Check your spot</Link>
          )}
        </ActionsContainer>
      </StyledContainer>
    </Nav>
  )
}
