import React, { useState } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { useLocation } from "@reach/router"
import * as queryString from "query-string"
import { emitter } from "@marvelapp/react-ab-test"

const URL = `${process.env.GATSBY_API_URL}/waitlist`

function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export default function SignupField({ centerText }) {
  const location = useLocation()

  const registered =
    typeof window !== "undefined" && window.localStorage.getItem("wait")
  const [error, setError] = useState()
  const { r } = queryString.parse(location.search)
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState("")
  const [submitted, setSubmitted] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault()
    const isValid = validateEmail(email)
    if (!isValid) {
      return setError("The email you entered was invalid")
    }
    setLoading(true)
    try {
      if (isValid) {
        const vals = { email }
        if (r) {
          vals.referred_by = r
        }

        const res = await fetch(URL, {
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify(vals),
        })
        if (!res.ok) {
          throw res
        }

        setSubmitted(true)
        emitter.emitWin("Email Signup")
      }
    } catch (e) {
      if (e.message) {
        setError(e.message)
      } else if (e.errors) {
        console.log(e.errors)
      } else {
        setError("Oops! Something went wrong.")
      }
    } finally {
      setLoading(false)
    }
  }
  return (
    <>
      {!submitted && (
        <HeaderForm onSubmit={handleSubmit}>
          <HeaderInput
            type={"email"}
            value={email}
            onChange={(e) => {
              const val = e.target.value

              if (!val || val.length < 1) {
                setError()
              }

              setEmail(val)
            }}
            placeholder="Your email"
          />
          <HeaderButton disabled={loading}>
            {loading ? <Spinner /> : "Get Early access"}
          </HeaderButton>
        </HeaderForm>
      )}
      {submitted && (
        <HeaderTextGroup centerText={centerText}>
          <br />
          <h2>Thanks For Signing Up!</h2>
          <p>Check your email to complete the process.</p>
        </HeaderTextGroup>
      )}
      {registered && (
        <FormSubtitle>
          Already on the list?{" "}
          <FormSubtitleLink to={`/waitlist/${registered}`}>
            Check your spot
          </FormSubtitleLink>
        </FormSubtitle>
      )}
      <br />
      <br />
      {error && <FormSubtitle error={error}>{error}</FormSubtitle>}
    </>
  )
}

const HeaderTextGroup = styled.div`
  margin: 0;
  ${(props) => props.centerText && `text-align: center;`}
  > div {
    margin-bottom: -4.5%;

    @media (max-width: ${(props) => props.theme.screen.md}) {
      margin: 0 16px;
    }
  }

  h1 {
    margin: 0 0 24px;
    color: ${(props) => props.theme.color.primary};
  }

  h2 {
    margin-bottom: 24px;
    ${(props) => props.theme.font_size.regular}
  }

  p {
    margin-bottom: 48px;
  }
`

const HeaderForm = styled.form`
  display: flex;
  flex-direction: row;
  padding-bottom: 16px;
  width: 100%;
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    flex-direction: column;
  }
`

const FormSubtitle = styled.span`
  color: ${(props) => (props.error ? "red" : "black")};
  ${(props) => props.theme.font_size.xxsmall}
`

const FormSubtitleLink = styled(Link)`
  color: ${(props) => props.theme.color.secondary};
  padding-bottom: 1px;
  margin-left: 8px;
  text-decoration: none;
  border-bottom: 1px solid ${(props) => props.theme.color.secondary};
`

const HeaderInput = styled.input`
  font-weight: 500;
  font-size: 16px;
  color: ${(props) => props.theme.color.primary};
  line-height: 42px;
  width: 100%;
  text-align: left;
  height: 60px;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) => props.theme.color.secondary};
  border-image: initial;
  border-radius: 4px;
  padding: 8px 16px;
  outline: 0px;
  &:focus {
    box-shadow: inset ${(props) => props.theme.color.secondary} 0px 0px 0px 2px;
  }
  @media (max-width: ${(props) => props.theme.screen.md}) {
    margin-bottom: 8px;
  }
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    display: block;
    width: 100%;
  }
`

const HeaderButton = styled.button`
  font-weight: 500;
  font-size: 14px;
  color: ${(props) => props.theme.color.white.regular};
  letter-spacing: 1px;
  height: 60px;
  display: block;
  margin-left: 8px;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  background: ${(props) => props.theme.color.secondary};
  border-radius: 4px;
  padding: 0px 20px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  outline: 0px;
  &:hover {
    box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px;
  }
  &:disabled {
    opacity: 0.5;
  }
  @media (max-width: ${(props) => props.theme.screen.md}) {
  }
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    margin-left: 0;
  }
`

const Spinner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  &:after {
    content: " ";
    display: block;
    width: 32px;
    height: 32px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`
